import { useContext } from "react";
import { BookingCContext } from "../../store/context/bookingContext";

function BookingTrackingStage() {
  let { contextData } = useContext(BookingCContext)
  return (
    <>
     <ul className='lbl3'>
      <li className={contextData?.usercurrentSlide == 2 || contextData?.usercurrentSlide == 3
        || contextData?.usercurrentSlide == 4
        ? 'sucess' : contextData?.usercurrentSlide == 1 ? 'active-tarck' : ''} >1</li>
      <li className={contextData?.usercurrentSlide == 3 || contextData?.usercurrentSlide == 4 ? 'sucess'
        : contextData?.usercurrentSlide == 2 ? 'active-tarck' : ''}>2</li>
      <li className={contextData?.usercurrentSlide == 4 ? 'sucess'

        : contextData?.usercurrentSlide == 3 ? 'active-tarck' : ''
      }>3</li>
      <li className={contextData?.usercurrentSlide == 4 ? 'sucess'

        : contextData?.usercurrentSlide == 4 ? 'active-tarck' : ''
      }>4</li>
    </ul>

  
    
    </>
   
  )
}

export default BookingTrackingStage;


