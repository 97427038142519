
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import '../../page/Booking/Booking.css'
import account from "../../assets/account.png"
import { useContext, useEffect, useState } from 'react'
import { BookingCContext } from '../../store/context/bookingContext'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { palcebooking } from '../../feature/booking'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import imgsuc from '../../assets/images.png'

// import PaymentModal from '../payment/paymentModal'

// This value is from the props in the UI
const style = {
    shape: "rect",
    layout: "horizontal",
    label: 'pay',
    tagline: false,
    height: 50,
    color: 'white'

    // horizontal:true
};
const customStyles = {
    content: {
        top: '20%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const customStyless = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function BookingPaymentDetails() {
    let { contextData, updateCarDetails, paymentbyOnline, bookingConCheck } = useContext(BookingCContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    let _payloadData = {
        form_location: {
            from_address: contextData?.fromAddressData?.from_address,
            from_latitude: contextData?.fromAddressData?.from_latitude,
            from_longitude: contextData?.fromAddressData?.from_longitude
        },
        to_location: {
            from_address: contextData?.toAddressData?.to_address,
            from_latitude: contextData?.toAddressData?.to_latitude,
            from_longitude: contextData?.toAddressData?.to_longitude
        },
        via_location: contextData?.viaaddressArray,
        distance_details: {
            total_distance: Math.floor(contextData?.totalDistance),
            total_time: `${contextData?.totalhourse} hours ${contextData?.totalminute} min`
        },
        location_pickup_details: {
            pickup_date: contextData?.location_pickup_details?.pickup_date,
            pickup_flight_no: contextData?.location_pickup_details?.pickup_flight_no,
            trip_type: contextData?.location_pickup_details?.trip_type,
            total_passenger: contextData?.location_pickup_details?.total_passenger,
            total_luggage: contextData?.location_pickup_details?.total_luggage,
            pickup_hour: contextData?.location_pickup_details?.pickup_time?.split(':')[0],
            pickup_minute: contextData?.location_pickup_details?.pickup_time?.split(':')[1],
            return_flight_no: contextData?.location_pickup_details?.return_flight_no,
            return_hour: contextData?.location_pickup_details?.return_time?.split(':')[0],
            return_minute: contextData?.location_pickup_details?.return_time?.split(':')[0],
            return_date: contextData?.location_pickup_details?.return_date
        },
        bookingcar: {
            car_id: contextData?.carDetails?.id
        },
        customer_details: {
            customer_name: contextData?.customerDetails?.customer_name,
            customer_email: contextData?.customerDetails?.customer_email,
            customer_phone: contextData?.customerDetails?.customer_phone,
            additional_information: contextData?.customerDetails?.additional_information
        },
        // payment_mode: 1
    }
    const [sucesfull, issuces] = useState(false)
    const mutation = useMutation(palcebooking, {
        onSuccess: (data) => {
            setLoading(true)
            // Optionally refetch or invalidate queries here
            toast.success(`${data?.message} Please check your email`)
            bookingConCheck()

            // issuces(true)
            setTimeout(() => {
                setLoading(false)
                navigate('/')
                window.location.reload()
            }, 5000);
            QueryClient.invalidateQueries(["placebooking"]); // Example


        },
        onError: (error) => {
            console.error("Error posting data:", error);
        },
    })

    function submitbooking(paym_mode) {
        mutation.mutate({ ..._payloadData, payment_mode: paym_mode })

    }

    return (
        <>
            <Modal
                isOpen={sucesfull}
                onAfterOpen={() => { }}
                // onRequestClose={() => { isReturnModalOpen(!retuenmodalOpen) }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{
                    width: 200,
                    height: 130,
                    display: "flex",
                    justifyContent: 'center',
                    position: 'relative',
                    margin: 0,
                    padding: 0

                }}>
                    <p style={{
                        fontSize: 20,
                        color: '#42f569',
                        position: 'absolute'
                    }}>
                        Payment Sucessfull
                    </p>
                    <img src={imgsuc}
                        style={{
                            height: 90,
                            width: 90,
                            mixBlendMode: "multiply",
                            objectFit: 'contain',
                            position: 'absolute',
                            marginTop: 20
                        }}
                    />
                    <div style={
                        {
                            position: 'relative'
                        }
                    }>


                    </div>

                </div>
            </Modal>
            <Modal
                isOpen={loading}
                onAfterOpen={() => { }}
                // onRequestClose={() => { isReturnModalOpen(!retuenmodalOpen) }}
                style={customStyless}
                contentLabel="Example Modal"
            >
                <div className="spinner"></div>
            </Modal>
            {/* <PaymentModal/> */}
            {/* <ToastContainer /> */}
            <div className='registration'>
                <div className='registrationtop'>
                    <div className='left'>
                        {/* <img src={contextData?.carDetails?.image_path} /> */}
                        <i className="fa fa-car" aria-hidden="true"></i>
                        <div>
                            <label>Vehicle</label>
                            <p>{contextData?.carDetails?.name
                            }{contextData?.carDetails?.model_no}</p>
                        </div>
                    </div>
                    <div className='right'>
                        <label>Charges:</label>
                        <p>${Math.floor(contextData?.carDetails?.total_charge)}</p>
                    </div>
                </div>
                <h3>Customer Details</h3>
                <p className='regItem'><label>Name :</label> {contextData?.customerDetails?.customer_name}</p>
                <p className='regItem'><label>Email :</label>{contextData?.customerDetails?.customer_email} </p>
                <p className='regItem'><label>Phone :</label> {contextData?.customerDetails?.customer_phone} </p>
                <p className='regItem'><label>Additional Information : </label><br />
                    {contextData?.customerDetails?.additional_information}
                </p>
                <img src={account}
                    className='payltr' />
                <div className='svbtnGrp'>

                    <PayPalButtons
                        style={style}
                        forceReRender={[style]}
                        disabled={false}
                        fundingSource={undefined}
                        // displayOnly={["vaultable"]}
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: Math.floor(contextData?.carDetails?.total_charge), // Amount from context
                                    },
                                }],
                            }).then(orderID => {
                                return orderID;
                            });


                        }}
                        onApprove={(data, actions) => {

                            return actions.order.capture().then(details => {

                                // alert('Transaction completed by ' + details?.payer?.name?.given_name);
                                issuces(true)
                                // setTimeout(() => {
                                //     navigate('/')
                                //     window.location.reload()

                                // }, 5000);
                                mutation.mutate({
                                    ..._payloadData, payment_mode: 1,
                                    transaction_id: details?.id,
                                    payment_id: details?.payer?.payer_id

                                })
                                // Call the booking function after payment success
                            });
                        }}
                        onError={(err) => {
                            console.error("PayPal Checkout onError", err);
                        }}

                    />
                    {/* <button type='button'
                        onClick={() => paymentbyOnline("openOnline", true)}
                        className='prvBtn backSucess'>Pay Now </button> */}
                    <button type='button'
                        onClick={() => submitbooking(2)}
                        disabled={mutation?.isLoading && contextData?.bookingConfirm ? true : false}

                        className='prvBtn_payltr payLtr'>Pay Later {mutation?.isLoading ? <i class="fa fa-spinner" aria-hidden="true"></i> : null}

                    </button>
                </div>
            </div>
        </>
    )
}

export default BookingPaymentDetails
