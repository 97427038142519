
import { useContext, useEffect } from 'react'
import '../../page/Booking/Booking.css'
import { Link } from 'react-router-dom'
import { BookingCContext } from '../../store/context/bookingContext'
import { customerDetails } from '../../util/schema'
import { useFormik } from 'formik'
function BookingCusDetails({ onValidate }) {
  let { contextData, nextCurentslide, updateCustomerDetails } = useContext(BookingCContext)
  const { values, errors, touched, handleBlur, handleChange, handleSubmit
  } = useFormik({
    initialValues: {
      customer_name: contextData?.customerDetails?.customer_name,
      customer_email: contextData?.customerDetails?.customer_email,
      customer_phone: contextData?.customerDetails?.customer_phone,
      additional_information: contextData?.customerDetails?.additional_information,
      terms: contextData?.customerDetails?.termscon,
      privacy: contextData?.customerDetails?.privacy
    },
    validationSchema: customerDetails,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,

    onSubmit: async (values) => {
      nextCurentslide()
      // Do the slide change only after form submission is successful
      // onValidate();
    }

  })
  useEffect(() => {
    if (onValidate) {
      onValidate(handleSubmit); // This makes the submitForm function available
    }
  }, [handleSubmit, onValidate]);


  useEffect(() => {
    console.log(contextData, 'conterjsrjslej')
  }, [contextData?.customerDetails?.privacy])

  return (
    <>
      <div className='registration'>
        <div className='registrationtop'>
          <div className='left'>
            {/* <img src={contextData?.carDetails?.image_path} /> */}
            <i className="fa fa-car" aria-hidden="true"></i>
            <div>
              <label>Vehicle</label>
              <p>{contextData?.carDetails?.name
              } {contextData?.carDetails?.model_no}</p>
            </div>
          </div>
          <div className='right'>
            <label>Price:</label>
            <p>${Math.floor(contextData?.carDetails?.total_charge)}</p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>


          <h3>Customer Details</h3>
          <div className='formgroup'>
            <label>Name *</label>
            <input
              name='customer_name'
              id='customer_name'
              value={values.customer_name}
              onChange={(e) => updateCustomerDetails('name', e.target.value)}
              type='text'
              placeholder='Enter Name' />
            {errors.customer_name ? <small className="invalidationtext">{errors?.customer_name}</small> : null}

          </div>
          <div className='formgroup'>
            <label>Email * </label>
            <input
              name='customer_email'
              id='customer_email'

              value={values.customer_email}
              onChange={(e) => updateCustomerDetails('email', e.target.value)}
              type='email'
              placeholder='Enter Email' />
            {errors.customer_email ? <small className="invalidationtext">{errors?.customer_email}</small> : null}

          </div>
          <div className='formgroup'>
            <label>Phone *</label>
            <input
              name='customer_phone'
              id='customer_phone'
              value={values.customer_phone}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              maxLength={10}
              autoCorrect='off'
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  updateCustomerDetails('phone', e.target.value);
                }
              }
              }
              type='number'
              placeholder='Enter Phone Number' />
            {errors.customer_phone ? <small className="invalidationtext">{errors?.customer_phone}</small> : null}

          </div>
          <div className='flxcheck'>
            <input
              type="checkbox"
              name='privacy'
              value='privacys'
              checked={values?.privacy}
              onChange={(e) => updateCustomerDetails('privacy', e.target.checked)}
              id="privacys"
            />
            <label htmlFor='privacys'  >I can confirm i have read and accepted the <Link target="_blank" rel="noopener noreferrer" to='/terms-and-conditions'>Privacy Police </Link></label>

            {errors.privacy ? <small className="invalidationtext">{errors?.privacy}</small> : null}

            <input
              type="checkbox"
              name='terms'
              onChange={(e) => updateCustomerDetails('terms', e.target.checked)}
              checked={values?.terms}
              id="termsc"
              value='termsc'
            />
            <label htmlFor='termsc'>I can confirm i have read and accepted the <Link target="_blank" rel="noopener noreferrer" to='/terms-and-conditions'>Terms and Conditions </Link></label>
            {errors.terms ? <small className="invalidationtext">{errors?.terms}</small> : null}

          </div>
          <div className='formgroup'>
            <label>Additional Information </label>
            <input
              name='additional_information'
              id='additional_information'
              onChange={(e) => updateCustomerDetails('addi', e.target.value)}

              value={values.additional_information}
              type='text'
              placeholder='Enter Additional Information ' />
          </div>

        </form>
      </div>
    </>
  )
}

export default BookingCusDetails
